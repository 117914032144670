import { ErrorNotify } from './notification'
import { envConfig } from '../config'
import { graphqlClient } from './graphqlClient'
import { gql } from 'apollo-boost'

const showError = error => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // ErrorNotify("User denied the request for Geolocation.")
      // ErrorNotify("Location Denied")
      break
    case error.POSITION_UNAVAILABLE:
      // ErrorNotify("Location information is unavailable.")
      break
    case error.TIMEOUT:
      // ErrorNotify("The request to get user location timed out.")
      break
    case error.UNKNOWN_ERROR:
      // ErrorNotify("Geolocation failed due to unknown error.")
      break

    default:
      break
  }
}

export const getGeoLocation = (getValue, fetchCountry = false) => {
  if (!navigator.geolocation) {
    ErrorNotify('Geolocation is not supported by your browser')
  } else {
    navigator.geolocation.getCurrentPosition(async position => await showPosition(position, getValue, fetchCountry), showError)
  }
}

const showPosition = async (position, getValue, fetchCountry) => {
  const latitude = position.coords.latitude
  const longitude = position.coords.longitude
  let country

  if (fetchCountry) {
    try {
      const { data } = await graphqlClient.query({
        query: gql`
          query getPlaceFromCoords($lat: Float!, $lng: Float!) {
            getPlaceFromCoords(lat: $lat, lng: $lng)
          }
        `,
        variables: { lat: latitude, lng: longitude }
      });
  
      if (data.getPlaceFromCoords.results) {
        let result;
        const address_components = data.getPlaceFromCoords.results[0].address_components
        address_components.map(component => {
          const addressType = component.types[0]
          if (addressType === 'country') {
            country = component.long_name
            result = (getValue({ lat: latitude, lng: longitude, country }))
          }
        })
        return result
      }
    } catch (err) {
      console.log('not working', err)
    }
  }

  return getValue({ lat: latitude, lng: longitude })
}

export const getPlaceInformation = place => {
  let result = {}

  if (place) {
    // result.placeName = place.name
    result.address = place.formatted_address
    result.latitude = place.geometry.location && place.geometry.location.lat()
    result.longitude = place.geometry.location && place.geometry.location.lng()

    if (place && place.address_components && place.address_components.length) {
      place.address_components.map(component => {
        const addressType = component.types[0]

        switch (addressType) {
          case 'street_number':
            result.streetNumber = component.long_name
            break
          case 'route':
            result.route = component.short_name
            break
          case 'locality':
            result.city = component.long_name
            break
          case 'administrative_area_level_1':
            result.state = component.long_name
            break
          case 'country':
            result.country = component.long_name
            break
          case 'postal_code':
            result.zip = component.long_name
            break

          default:
            break
        }
      })
    }
  }

  return { ...result }
}
