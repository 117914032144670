import React, { useContext, useState, Fragment, useEffect } from 'react'
import ReactTable from 'react-table'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import UserContext from '../../UserContext'
import { SkeletonLoader } from '../loader/SkeletonLoader'
import { defaultImage, iconBoldImages, pagination, shareModule, skeletonType, tableText, userRoleTypes } from '../../util/enums/enums'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import '../../components/tableComponent/TableComponent.scss'
import '../../styles/common.scss'
import 'react-table/react-table.css'
import { InputBase, Switch } from '@material-ui/core'
import { Col, Container, Row } from 'react-bootstrap'
import { cityCountryNameFormatter, getAddress, getPagesValue, redirectToUserProfile } from '../../helpers/jsxHelper'
import { nameFormatter } from '../../helpers/string'
import { CommonTooltip } from '../CommonTooltip'
import { PaginationBar } from '../pagination/PaginationBar'
import { Link } from 'react-router-dom'
import { getUsdPrice } from '../../helpers/currencyConverterHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { debounce } from 'lodash'
import ChatUserCreate from '../chats/ChatCreateUse'
import OutsideClickHandler from 'react-outside-click-handler'
import SharePopup from '../share/SharePopup'

export const TableCard = ({
  title,
  titleDesc,
  headerContent,
  button,
  buttonLg,
  columns,
  data,
  noButton,
  width,
  loading,
  isSalesEngine,
  showArchiveSalesEngine,
  className,
  messageType,
  message,
  onClick,
  otherButton,
  openModal,
  setModalOpen,
  isSearch,
  isDashboardCard,
  manufactureData,
  currentUser,
  isDealerData,
  isManufacturerData,
  acceptOrRejectDealerRequest,
  action,
  total,
  searchData,
  searchType,
  searchResultKey,
  clearSearchDashboard,
  manufactureDealerAction,
  manufactureDealerTotal,
  searchParams,
  extraValues,
  dashboardSearchInput = {},
  refresh,
  helpModalContent,
}) => {
  const [, setrefresh] = useState(0)
  useEffect(() => {
    setrefresh(0)
  }, [refresh])
  const { history } = useContext(UserContext)

  const [show, setShow] = useState(false)
  const [sortingInfo, setSortingInfo] = useState([])
  const [sort, saveSortType] = useState({ sort: 'DESCENDING', sortKey: '' })
  const [search, setSearch] = useState('')
  const [selectedIndex, setSelectedIndex] = React.useState(null)

  const helpModalHandler = () => setShow(!show)

  const redirectHandler = otherButton => {
    if (!noButton && history && otherButton) {
      history.push(`/${otherButton}`)
    }
  }

  const renderHelpModal = () => {
    return (
      <Fragment>
        <div className="do-you-help-content">
          {message && <span className={`alert alert-${messageType}`}>{message}</span>}
          <span className="cursor-pointer" onClick={helpModalHandler}>
            {' '}
            {tableText.needText}
          </span>
        </div>
        <CommonModal
          className="sales-engine-header-help-dialog dashboard--table--help--section w-90"
          open={show}
          close={helpModalHandler}
          title="Help"
        >
          <p className="mb-3 dashboard--table--help--desc" dangerouslySetInnerHTML={{ __html: helpModalContent || '' }} />

          <ButtonComponent className="m-auto h-auto as--cancel--outline btn--padding btn--font " onClick={helpModalHandler}>
            Close
          </ButtonComponent>
        </CommonModal>
      </Fragment>
    )
  }
  const renderArchiveSalesEngine = () => {
    return (
      <div className="archive-sales-engine-content">
        <FolderOpenIcon />
        <Link to="/sales-engine-archives">{tableText.archiveSalesEngine}</Link>
      </div>
    )
  }
  const getFormattedCityCountry = item => {
    const {
      commonAddress: { city, country },
    } = getAddress(isDealerData ? item?.dealer?.address : item?.address)
    return cityCountryNameFormatter(city, country)
  }

  const handleSortChange = data => {
    //in 1st page it will not call the API to sort: handled by the library itself
    if (!total || total <= pagination.PAGE_RECORD_LIMIT) return

    const type = { asce: 'ASCENDING', desc: 'DESCENDING' }

    const sort = data[0]?.desc ? type.desc : type.asce
    const sortDetails = data[0].desc ? {} : { sort: sort, sortKey: data[0]?.id }
    saveSortType(sortDetails)
    setSortingInfo(data)
    action({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
      ...sortDetails,
    })
  }

  const debounceSearchAction = debounce(async searchedValue => {
    searchAction(searchedValue)
  }, 1000)

  const resetPage = () => {
    const urlParams = new URLSearchParams(history.location.search)
    let searchParams
    if (urlParams.has('page')) {
      searchParams = location.search.split('page')
    }
    const search = searchParams?.length ? `${searchParams[0]}page=1` : `${location.search}&page=1`

    history.replace({
      pathname: location.pathname,
      search,
      state: location.state,
    })
  }

  const searchAction = searchedValue => {
    const paginationQuery = {
      page: getPagesValue() || pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
    }
    const otherParams = searchParams?.boatId ? { boatId: searchParams?.boatId } : {}
    setSearch(searchedValue)
    resetPage()
    if (searchedValue) {
      if (searchData) {
        searchData({
          input: {
            ...otherParams,
            ...dashboardSearchInput,
            searchTerm: searchedValue.trim(),
            type: searchType,
          },
          ...(paginationQuery.page === pagination.PAGE_COUNT ? paginationQuery : {}),
          searchType: searchResultKey,
        })
      } else if (manufactureDealerAction) {
        manufactureDealerAction({
          ...extraValues,
          ...otherParams,
          ...(paginationQuery.page === pagination.PAGE_COUNT ? paginationQuery : {}),
          searchTerm: searchedValue.trim(),
        })
      } else {
        action({
          ...otherParams,
          ...extraValues,
          ...(paginationQuery.page === pagination.PAGE_COUNT ? paginationQuery : {}),
          searchTerm: searchedValue.trim(),
        })
      }
    } else {
      if (manufactureDealerAction) {
        manufactureDealerAction({ ...extraValues, ...paginationQuery })
      } else {
        action({ ...extraValues, ...otherParams, ...paginationQuery })
      }
      clearSearchDashboard()
    }
    resetPage()
  }
  const urlParams = new URLSearchParams(history.location.search)

  const pageCount = urlParams.has('page') ? urlParams.get('page') : 1

  return (
    <>
      <div className={`${className} row m-0`}>
        <div className="col-12">
          <div className="card table--card">
            <div className="card-header article-header d-flex align-items-center flex-wrap">
              <h4 className="card-title d-flex align-items-center table--main--tilte mb-2">{title}</h4>

              {headerContent}

              {isSalesEngine && (
                <div className="middle--content-table">
                  {showArchiveSalesEngine && renderArchiveSalesEngine()}
                  {isSalesEngine && renderHelpModal()}
                </div>
              )}

              {isSearch ? (
                <div className="search--data--input mb-2">
                  <img loading="lazy" src={require('../../assets/images/salesEngine/search.svg')} alt="Search" className="search--icon" />
                  <InputBase
                    placeholder="Search Here..."
                    className="search-input"
                    onChange={e => debounceSearchAction(e.target.value)}
                  />
                </div>
              ) : (
                <div className="search">&nbsp;</div>
              )}

              {!noButton && (
                <>
                  <ButtonComponent
                    variant="outline"
                    color="success"
                    className={"btn--padding btn--font mb-2 add-btn-lg text-transform-none"}
                    onClick={() => (openModal ? setModalOpen() : redirectHandler(button.url))}
                  >
                    {button.name}
                  </ButtonComponent>
                </>
              )}
            </div>

            <div className={isDashboardCard ? 'card-body mt-0 manufacture--card--spacing' : 'card-body mt-0'}>
              <div className="table-responsive react--dashboard--table dashboard--react--table">
                {data &&
                  (loading ? (
                    <SkeletonLoader type={skeletonType.table} />
                  ) : isDashboardCard ? (
                    <>
                      <div className="manufacture--card--total--count">({manufactureData?.length || 0}) Request(s) available</div>
                      <Container fluid className="p-0">
                        <Row className="m-0">
                          {manufactureData?.length > 0 &&
                            manufactureData.map((item, i) => {
                              const userFirstName = isDealerData ? item.dealer?.firstName : item.firstName
                              const userLastName = isDealerData ? item.dealer?.lastName : item.lastName
                              const manufacturerStatus =
                                isManufacturerData &&
                                item?.dealerRequests?.length > 0 &&
                                item?.dealerRequests.find(data => data?.dealer?.id === currentUser?.id)
                              const verified = isManufacturerData ? item?.isVerified : item?.dealer?.isVerified

                              return (
                                <Col xs={12} className="p-0" key={item.id}>
                                  <div className="dashboard--manufacture--row">
                                    <div className="d-flex dashboard--manufacture--first--sec">
                                      {/* <div className="dashboard--manufacture--count">
                                        <span>{i + 1 + (pageCount * 10 - 10)}.</span>
                                      </div> */}
                                      <div className="dashboard--manufacture--logo--switch">
                                        <div className="dashboard--manufacture--logo">
                                          <img
                                            src={
                                              (isDealerData ? item?.dealer?.companyLogo?.url : item?.companyLogo?.url) ||
                                              defaultImage
                                            }
                                            className=""
                                            alt="logo"
                                          />
                                        </div>
                                        {item?.accepted && (
                                          <div className="dashboard--manufacture--switch">
                                              <CommonTooltip component={ctProps => (
                                                <Switch
                                                  data-tooltip-id={ctProps.id}
                                                  data-tooltip-content={item.status ? 'Disable' : 'Enable'}
                                                  checked={item.status}
                                                  onChange={() =>
                                                    acceptOrRejectDealerRequest({
                                                      dealerId: item?.dealer?.id,
                                                      columnName: 'status',
                                                      value: !item?.status,
                                                    })
                                                  }
                                                  value="showMap"
                                                  color="primary"
                                                />
                                              )} />
                                          </div>
                                        )}
                                      </div>

                                      <div>
                                        <div className="d-flex flex-column pr-4">
                                          <span className="dashboard--manufacture--location">
                                            {getFormattedCityCountry(item)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dashboard--manufacture--second--sec">
                                      <div className="d-flex flex-column">
                                        {isDealerData && (
                                          <span className="dashboard--manufacture--company">
                                            Company Name:{' '}
                                            <span className="manufacture--dealer--company--name">
                                              {item?.dealer?.companyName}
                                            </span>
                                          </span>
                                        )}
                                        {isManufacturerData && (
                                          <span className="dashboard--manufacture--company">
                                            Company Name: <span className="manufacture--company--name">{item.companyName}</span>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="d-flex dashboard--manufacture--third--sec">
                                      <div className="dashboard--manufacture--verified--btn">
                                        <div className="d-flex align-items-center dashboard--manufacture--verified">
                                          <div>
                                            <img
                                              src={
                                                verified
                                                  ? require('../../components/userProfile/image/vector.png')
                                                  : require('../../components/userProfile/image/cross-icon.svg')
                                              }
                                              className="dashboard--manufacture--verified--icon"
                                              alt="Verify icon"
                                            />
                                          </div>
                                          <div>
                                            <span className="dashboard--manufacture--unverified--text">
                                              {verified ? 'Verified' : 'Not Verified'}
                                            </span>
                                          </div>
                                        </div>

                                        {isManufacturerData && (
                                          <button
                                            className={`${
                                              manufacturerStatus?.accepted ? 'accepted--btn' : 'rejected--btn'
                                            } btn dashboard--manufacture--btn dashboard--manufacture--accept--btn`}
                                          >
                                            {manufacturerStatus?.accepted ? 'Accepted' : 'Requested'}
                                          </button>
                                        )}
                                        {!item?.accepted && !isManufacturerData && (
                                          <div className="d-flex dashboard--manufacture--btn--section">
                                            <button
                                              className="btn dashboard--manufacture--btn dashboard--manufacture--accept--btn"
                                              onClick={() =>
                                                acceptOrRejectDealerRequest({
                                                  dealerId: item?.dealer?.id,
                                                  columnName: 'accepted',
                                                  value: true,
                                                })
                                              }
                                            >
                                              {'Accept'}
                                            </button>
                                            <button
                                              className="btn dashboard--manufacture--btn dashboard--manufacture--reject--btn"
                                              onClick={() =>
                                                acceptOrRejectDealerRequest({
                                                  dealerId: item?.dealer?.id,
                                                  columnName: 'accepted',
                                                  value: false,
                                                })
                                              }
                                            >
                                              {'Reject'}
                                            </button>
                                          </div>
                                        )}

                                        <div className="dashboard--manufacture--social--detail d-flex">
                                          <ChatUserCreate id={isDealerData ? item?.dealer?.id : item?.id} image={isDealerData ? item?.dealer?.image?.url : item?.image?.url} />
                                          <OutsideClickHandler onOutsideClick={() => setSelectedIndex(null)}>
                                            <CommonTooltip component={ctProps => (
                                              <img
                                                data-tooltip-id={ctProps.id}
                                                data-tooltip-content="Share"
                                                src={iconBoldImages.share}
                                                className="user-profile-social-icon-div cursor-pointer"
                                                alt="Share"
                                                onClick={() => setSelectedIndex(isDealerData ? item?.dealer?.id : item?.id)}
                                              />
                                            )} />
                                            <SharePopup
                                              handleClick={() => setSelectedIndex(null)}
                                              useOwnIcon
                                              moduleType={shareModule.USER}
                                              moduleId={isDealerData ? item?.dealer?.id : item?.id}
                                              index={isDealerData ? item?.dealer?.id : item?.id}
                                              selectedIndex={selectedIndex}
                                              sharedUrl={redirectToUserProfile(isDealerData ? item?.dealer : item, history, null, true)}
                                            />
                                          </OutsideClickHandler>
                                        </div>
                                      </div>
                                      <div className="dashboard--manufacture--name--user">
                                        <div className="dashboard--manufacture--user">
                                          <img
                                            src={(isDealerData ? item?.dealer?.image?.url : item?.image?.url) || defaultImage}
                                            className="rounded-circle"
                                            alt="Dealer"
                                            onClick={() => redirectToUserProfile(isDealerData ? item?.dealer : item, history)}
                                          />
                                          <div></div>
                                        </div>
                                        <p className="dashboard--manufacture--name">
                                          {nameFormatter([userFirstName, userLastName])}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )
                            })}
                        </Row>

                        {manufactureDealerTotal > pagination.MARKET_PAGE_LIMIT && (
                          <div className="boat-pagination home--show--all--pagination">
                            <PaginationBar
                              action={manufactureDealerAction}
                              totalRecords={manufactureDealerTotal}
                              pageLimit={pagination.MARKET_PAGE_LIMIT}
                            />
                          </div>
                        )}
                      </Container>
                    </>
                  ) : (
                    <ReactTable
                      minRows={3}
                      columns={columns}
                      data={data}
                      defaultFilterMethod={(filter, row) => String(row[filter.id]).includes(filter.value)}
                      className="-striped -highlight"
                      style={{ width: width }}
                      defaultSorted={sortingInfo}
                      onSortedChange={handleSortChange}
                      PaginationComponent={PaginationBar}
                      getPaginationProps={() => ({
                        action: action,
                        searchAction: searchData ? searchData : undefined,
                        searchType: searchType,
                        searchResultKey,
                        totalRecords: total,
                        dashboardSearchInput: dashboardSearchInput,
                        pageLimit: pagination.PAGE_RECORD_LIMIT,
                        value: { ...extraValues, ...sort, searchTerm: search },
                      })}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TableCard.defaultProps = {
  title: '',
  button: { name: '', url: '' },
  columns: [],
  data: [],
  noButton: false,
  isSalesEngine: false,
  loading: null,
  className: '',
  openModal: false,
  setModalOpen: false,
}
